@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.select-races {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.select-races-shadow-container {
    position: relative;
    width: 90%;
}
  
.select-races-title {
    width: 90%;
    text-align: left;
    border-top: 10px solid #FDDD07;
    box-shadow: -1px -1px 30px rgb(0, 0, 0, 0.1),
                  1px -1px 30px rgb(0, 0, 0, 0.1),
                  -1px 1px 30px rgb(0, 0, 0, 0.1),
                  1px 1px 30px rgb(0, 0, 0, 0.1);
}

.select-races-shadow-mask-right, .select-races-shadow-mask-bottom {
    position: absolute;
    background-color: white; 
    z-index: 2; 
}

.select-races-shadow-mask-right {
    top: 0;
    right: 5%;
    bottom: 0;
    width: 5%; 
}

.select-races-shadow-mask-bottom {
    bottom: -30px;
    left: 0;
    right: 0;
    height: 31px;
}

.select-races-title h1 {
    margin: 0;
    font-family: "NerisBlack";
    font-style: italic;
    font-size: 32px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.select-races-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    height: 120px;
}
  
.select-races-rules {
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 8%;
}

.select-races-rules p {
    font-family: "NerisBlack";
    font-size: 26px;
}

.select-races-change-step {
    text-align: center;
    display: flex;
    gap: 7%;
    align-items: center;
    height: 100%;
    padding-right: 3%;
}

.select-races-change-step button {
    font-family: "NerisBlack";
    font-style: italic;
    color: #fff70d;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 250px;
    height: 50PX;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.select-races-change-step button:disabled {
    color: rgba(120, 120, 120, 0.5);
    text-shadow: none;
}

.select-races-pannel-view {
    display: flex;
    width: 100%;
}

.select-races-choice {
    display: flex;
    flex-direction: column;
    width: 70%;
    border-right: 1px solid #C8CBD9;
}

.select-races-choice h2 {
    margin-left: 5%;
}
  
.select-races-controls {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    padding-left: 10%;
    padding-bottom: 2%;
}
  
.select-races-sort-control {
    font-family: "NerisBlack";
    font-size: 18px;
    letter-spacing: 2px;
}
  
.select-races-sort-control {
    display: flex;
    align-items: center;
}

.select-races-sort-control select {
    font-family: "Neris";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
    margin-left: 20px;
}
  
.select-races-table-container {
    width: 85%;
    overflow-y: auto;
    max-height: 1000px;
    margin-left: 10%;
    padding: 0;
    letter-spacing: 0px;
    border: 1px solid black;
}

.select-races-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 11px;
    background-color: #FAFAFA;
}

.select-races-table-selected-row {
    background: yellow;
    font-family: "NerisBlack";
}

.select-races-table-container th,
.select-races-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;    
    border-left: none;
}

.select-races-table-container td {
    cursor: pointer;
    font-size: 13px;
}

.select-races-table-container th {
    font-family: "NerisBlack";
    font-size: 14px;
    background-color: #0056b3;
    color: #fff70d;
    font-style: italic;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
}

.select-races-table-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 10px;
}

.select-races-table-name-container span {
    text-align: left;
    width: 60%;
}

.select-races-table-flag-icon {
    margin-right: 10px;
}

.select-races-choice-view {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-top: 3%;
}

.select-races-selected-number {
    padding-left: 50px;
    font-family: "NerisBlack";
    font-size: 18px;
}

.select-races-selected-races-green {
    color: #34A853;
    padding-left: 4px;
}

.select-races-selected-races-red {
    color: #ED1566;
    padding-left: 4px;
}

.select-races-scroll-container {
    display: flex;
    overflow-y: auto;
    max-height: 80%;
}

.select-races-line-container {
    display: flex;
    flex-direction: column;
    max-height: 1000px;
    margin: 0 auto;
    flex-wrap: nowrap; 
}

.select-races-selected-cell {
    flex: 1 1 auto; 
}

.select-races-line-container:last-child .select-races-selected-cell:last-child {
    margin-right: 0;
}

::-webkit-scrollbar {
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.select-races-selected-info {
    font-size: 12px;
    font-family: "NerisBlack";
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 10px;
    background-color: #EDEDED40;
    margin: 10px 10px;
    width: 150px;
    height: 80px;
    text-align: left;
    letter-spacing: 0;
    position: relative;
}

.select-races-selected-info img {
    position: absolute;
    right: 5%;
    cursor: pointer;
}

.select-races-selected-info p img {
    position: relative;
    left: 1px;
}

.select-races-selected-info h4, .select-races-selected-info p {
    margin: 0;
}

.select-races-selected-info p {
    font-family: "Neris";
}

.select-races-selected-name-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 5px;
}

.select-races-selected-name-container img {
    position: relative;
}

.profil-cyclists-name-container h4 {
    text-align: left;
}

.select-races-selected-flag-icon {
    margin-right: 0px;
}

@media (max-width: 1499px) and (min-width: 700px) {
    .select-races-title h1 {
        font-size: 32px;
    }

    .select-races-rules p {
        font-size: 24px;
    }

    .select-races-change-step button {
        font-size: 20px;
        border-radius: 80px;
        width: 200px;
    }
}

@media (max-width: 699px) {
    .select-races {
        padding-top: 0;
        padding-bottom: 5%;
    }

    .select-races-shadow-container {
        width: 100%;
    }

    .select-races-shadow-mask-right, .select-races-shadow-mask-bottom {
        display: none;
    }

    .select-races-title {
        text-align: center;
        width: 100%; 
        box-shadow: none;
        display: flex;
        justify-content: center;
    }

    .select-races-title h1 {
        width: 90%;
        margin: 0;
        font-size: 28px;
        padding-left: 0px;
        padding-top: 50px;
    }

    .select-races-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 0;
        height: 120px;
        margin-bottom: 30px;
    }

    .select-races-rules {
        text-align: center;
        padding-left: 0;
        padding-bottom: 10%;
    }

    .select-races-rules p {
        font-size: 22px;
        margin: 0;
        padding-left: 0px;
    }

    .select-races-change-step {
        padding: 0 20px;
    }

    .select-races-change-step button, .select-races-popup-content button {
        font-size: 20px;
        border-radius: 80px;
        width: 200px;
        height: 50px;
    }

    .select-races-pannel-view {
        flex-direction: column;
    }

    .select-races-choice:first-child {
        order: 1;
    }

    .select-races-choice-view:first-child {
        order: 0;
    }

    .select-races-choice-view {
        width: 100%;
        padding-top: 0;
    }

    .select-races-choice {
        width: 100%;
    }

    .select-races-table-container {
        width: 100%;
        overflow-y: auto;
        max-height: 500px;
        margin-left: 0%;
    }

    .select-races-table-container::-webkit-scrollbar {
        display: none;
    }

    .select-races-table-container table {
        width: 800px;
    }

    .select-races-table-container th,
    .select-races-table-container td {
        padding: 9px 0;
    }

    .select-races-table-container td {
        cursor: pointer;
        font-size: 11px;
    }
    
    .select-races-table-container th {
        font-family: "NerisBlack";
        border-top: none;
        position: sticky;
        top: 0;
        z-index: 100;
        font-size: 12px;
    }

    .select-races-line-container {
        display: none;
    }

    .select-races-controls {
        width: 100%;
        padding-left: 0;
        padding-bottom: 2%;
    }

    .select-races-sort-control {
        letter-spacing: 0;
        font-size: 14px;
        padding-left: 2%;
    }

    .select-races-sort-control select {
        margin-left: 10px;
    }

    .select-races-table-name-container {
        padding-left: 5px;
    }
}