@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.cyclistDetail {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    font-family: "Neris";
}

.cyclistDetail h1 {
    font-family: "NerisBlack";
    font-size: 40px;
    font-style: italic;
    color: #1F384C;
    margin: 0;
}

.cyclistDetail-choice {
    text-align: center;
    display: flex;
    gap: 10%;
    align-items: center;
    height: 100%;
    padding: 3% 0;
}

.cyclistDetail-choice button {
    font-family: "NerisBlack";
    font-style: italic;
    color: white;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 250px;
    height: 60px;
    margin: 0;
    padding: 0;
    background-color: #FDDD07;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.cyclistDetail-link-back-to-ranking-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cyclistDetail-link-back-to-ranking {
    text-decoration: none;
    font-family: "NerisBlack";
    font-style: italic;
    color: white;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 300px;
    margin: 0;
    padding-bottom: 17px;
    padding-top: 10px;
    background-color: #0056b3;
    color: #fff70d;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
    
}

.cyclistDetail-sort-control {
    font-size: 16px;
    letter-spacing: 0;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-left: 2%;
}

.cyclistDetail-sort-control select {
    font-family: "Neris";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
    margin-left: 10px;
}


.cyclistDetail-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
}
  
.cyclistDetail-table-container {
    width: 80%;
    overflow-y: auto;
    max-height: 500px;
    padding: 0;
    letter-spacing: 0px;
}

.cyclistDetail-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 11px;
    background-color: #FAFAFA;
}

.cyclistDetail-table-container th,
.cyclistDetail-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.cyclistDetail-table-container td {
    font-family: "Neris";
    cursor: pointer;
    font-size: 14px;
}

.cyclistDetail-table-container th {
    font-family: "NerisBlack";
    background-color: #FFE220;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 16px;
}

.cyclistDetail-name-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cyclistDetail-name-info span {
    text-align: left;
    width: 30%;
}

.cyclistDetail-name-bonus {
    width: 10% !important;
}

.cyclistDetail-flag-icon {
    margin-right: 10px;
}

@media (max-width: 699px) {
    .cyclistDetail {
        margin: 0;
    }

    .cyclistDetail h1 {
        font-size: 26px;
        padding: 0 0;
        margin: 0;
        margin-top: 15%;
        margin-bottom: 5%;
        text-align: center;
    }   

    .cyclistDetail-choice {
        display: flex;
        flex-direction: column;
        gap: 0%;
        align-items: center;
        height: 100%;
        padding-bottom: 5%;
    }

    .cyclistDetail button {
        font-size: 20px;
        width: 200px;
        height: 50px;
        margin: 5px 0;
    }

    .cyclistDetail-table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .cyclistDetail-table-container {
        scrollbar-width: none;
        width: 100%;
        max-height: 65vh;
    }

    .cyclistDetail-table-container::-webkit-scrollbar {
        display: none;
    }

    .cyclistDetail-name-container {
        padding-left: 0%;
    }

    .cyclistDetail-table-container th,
    .cyclistDetail-table-container td {
        padding: 9px 3px;
    }

    .cyclistDetail-table-container td {
        font-size: 12px;
    }

    .cyclistDetail-table-container th {
        font-size: 14px;
    }

    .cyclistDetail-name-info {
        justify-content: flex-start;
        padding-left: 5px;
    }

    .cyclistDetail-name-bonus {
        width: 20% !important;
    }
}