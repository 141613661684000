@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.sign-up {
    text-align: center;
    position: relative;
    font-family: "NerisBlack";
    color: white;
}

.sign-up-header {
    position: relative;
}

.sign-up-header::before {
    content: "";
    position: absolute; 
    bottom: 0; 
    left: 0;
    right: 0;
    height: 25%; 
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 2;
}

.sign-up-header img {
    width: 100%;
    height: 100vh;
}

.sign-up-title {
    position: absolute;
    top: 10%; 
    left: 50%;
    transform: translateX(-50%); 
    font-size: 64px;
    font-style: italic;
    z-index: 3;
}

.sign-up-content {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-content p {
    font-size: 28px;
    padding: 5%;
}

.sign-up-content button {
    background-color: black;
    color: white;
    font-family: "NerisBlack";
    font-size: 32px;
    border: none;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 20px 60px;
    cursor: pointer;   
}

.sign-up-login-link {
    margin-top: 20px;
}

.sign-up-login-link a {
    text-decoration: none;
    color: black;
}

.sign-up-login-link a:hover {
    text-decoration: underline;
}

/* Sign-up Form */
.sign-up-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: "Neris";
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 50%;
}

.sign-up-background {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 490px;
    height: 562px;
    z-index: -1;
}

.sign-up-form-title {
    text-align: center;
    color: black;
    font-family: "Neris";
    font-size: 22px;
}

.sign-up-form-control {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px 20px;
    color: black;
    width: 35%;
}

.sign-up-form-control input {
    border: none;
    background-color: #F6F8FB;
    border-radius: 16px;
    padding: 15px 30px;
    height: 30px;
}

.sign-up-form-control select {
    border: none;
    background-color: #F6F8FB;
    border-radius: 16px;
    padding: 15px 30px;
    height: 30px;
}

.sign-up-form-control input[type="password"] {
    -webkit-text-security: disc;
}

.sign-up-form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
}

.sign-up-form-row input[type="text"],
.sign-up-form-row input[type="email"],
.sign-up-form-row input[type="password"],
.sign-up-form-row select {
    flex: 1;
    margin: 5px;
}

.sign-up-form-row label {
    display: flex;
    align-items: center;
    font-size: 10px;
    gap: 5px;
    color: black;
    padding-left: 10px;
}

.sign-up-form-row.button-row {
    justify-content: center;
}

.sign-up-form-label-condition {
    font-size: 14px !important;
    padding-left: 20px !important;
}

.sign-up-form-visibility-icon {
    position: absolute;
    right: 35px;
    top: 45%;
    color: yellow;
    cursor: pointer;
}

.sign-up-form-row.button-row button {
    width: 50%;
    padding: 10px;
    margin-top: 40px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "NerisBlack";
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

@media (max-width: 1700px) and (min-width: 1500px) {
    .sign-up-content p {
        padding: 2%;
    }

    .sign-up-login-link {
        margin-top: 20px;
    }
}

@media (max-width: 1499px) and (min-width: 700px) {
    .sign-up-title {
        font-size: 50px;
    }

    .sign-up-content p {
        font-size: 22px;
        padding-bottom: 2%;
    }

    .sign-up-content button {
        font-size: 26px;
        padding: 15px 50px;
    }

    .sign-up-background {
        width: 392px;
        height: 450px;
    }

    .sign-up-login-link {
        margin-top: 20px;
    }
}

@media (max-width: 699px) {
    .sign-up-header {
        position: fixed;
    }

    .sign-up-header::before {
        display: none;
    }

    .sign-up-header img {
        height: 100vh;
        object-fit: cover;
    }

    .sign-up-content {
        position: fixed;
        width: 96%;
        bottom: 0;
        top: 35%;
    }

    .sign-up-title {
        position: fixed;
        top: 10%; 
        width: 100%;
        font-size: 32px;
    }

    .sign-up-content p {
        font-size: 18px;
        padding: 0%;
        width: 100%;
    }

    .sign-up-content button {
        margin: 10% 0;
        font-size: 24px;
        padding: 15px 45px;
    }

    .sign-up-background {
        display: none;
    }

    .sign-up-form {
        width: 100%;
        justify-content: center;
    }

    .sign-up-form-title {
        margin-top: 50px;
    }

    .sign-up-form-row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sign-up-form-control {
        width: 80%;
    }
    
    .sign-up-form-row.button-row button {
        margin-bottom: 20px;
    }
}