@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.my-ranking {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.my-ranking-choice {
    text-align: center;
    display: flex;
    gap: 10%;
    align-items: center;
    height: 100%;
    padding: 3% 0;
}

.my-ranking-choice-ranking {
    text-align: center;
    display: flex;
    gap: 20PX;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
}

.my-ranking-choice button{
    font-family: "NerisBlack";
    font-style: italic;
    cursor: pointer;
    font-size: 20px;
    width: 200px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    color: #fff70d;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.my-ranking-choice-ranking button {
    font-family: "NerisBlack";
    font-style: italic;
    cursor: pointer;
    font-size: 18px;
    width: 160px;
    height: 40px;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    color: #fff70d;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.my-ranking-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
}
  
.my-ranking-table-container {
    width: 80%;
    overflow-y: auto;
    max-height: 500px;
    padding: 0;
    letter-spacing: 0px;
}

.my-ranking-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 11px;
    background-color: #FAFAFA;
}

.my-ranking-table-container th,
.my-ranking-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.my-ranking-table-container td {
    font-family: "Neris";
    cursor: pointer;
    font-size: 14px;
}

.my-ranking-table-container th {
    font-family: "NerisBlack";
    background-color: #0056b3;
    color: #fff70d;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 16px;
}

.my-ranking-name-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.my-ranking-name-info span {
    text-align: left;
    width: 30%;
}

.my-ranking-flag-icon {
    margin-right: 10px;
}
.highlighted {
    background-color: #fde861;
}

@media (max-width: 699px) {
    .my-ranking {
        margin: 0;
    }

    .my-ranking h1 {
        font-size: 26px;
        padding: 0 0;
        margin: 0;
        margin-top: 15%;
        margin-bottom: 5%;
        text-align: center;
    }   

    .my-ranking-table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .my-ranking-table-container {
        scrollbar-width: none;
        width: 100%;
        max-height: 65vh;
    }

    .my-ranking-table-container::-webkit-scrollbar {
        display: none;
    }

    .my-ranking-choice,
    .my-ranking-choice-ranking {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        height: 100%;
        padding-bottom: 5%;
    }

    .my-ranking-choice button {
        font-size: 20px;
        width: 200px;
        height: 50px;
        margin: 5px 0;
    }
}