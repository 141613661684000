@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

/* Global */
.profil {
    font-family: "Neris";
    display: flex;
    width: 100%;
    margin-top: 71px;
}

.profil-menu {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    z-index: 10000;
    background-color: white;
}

.profil-menu-profil {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 50px;
}

.profil-menu-profil img {
    margin-top: 20px;
    width: 300px;
}

.profil-menu-profil p {
    font-size: "NerisBlack";
    font-weight: 700;
    font-size: 12px;
    margin: 0;
    text-align: left;
}

.profil-menu-right {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profil-menu-onglet {
    width: 100%;
    display: flex;
    gap: 30px;
}

.profil-pub {
    margin-bottom: 3px;
}

.profil-menu-onglet h4 {
    font-size: 16px;
    font-family: "NerisBlack";
    font-style: italic;
    color: #002394;
    margin: 20px 0;
    padding: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
}

.profil-menu-onglet h4.active {
    background-color: #fff70d;
}

.profil-infos {
    display: flex;
    gap: 30px;
    align-items: center;
    height: 100%;
}

.profil-image-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.profil-image {
    width: 100px;
    height: auto;
    align-self: center;
}

.profil-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 100%;
}

.profil-row {
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    display: flex;
}

.profil-row-flag {
    width: 16px;
    height: 16px;
}

.profil-badge {
    background-color: #dce4f7;
    color: #0056b3;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
}

.profil-content-container {
    padding-top: 230px;
    position: relative;
    width: 100%;
    overflow-y: auto;
}

@media (max-width: 699px) {
    .profil {
        margin-top: 0;
        flex-direction: column;
    }

    .profil-menu {
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
    }
    
    .profil-menu-profil {
        margin: 0;
        width: auto;
        padding: 0;
    }

    .profil-menu-profil p {
        padding: 0 10px;
    }

    .profil-menu-profil img {
        margin-top: 0;
        width: 100%;
        margin-bottom: 10px;
    }

    .profil-menu-right {
        align-items: center;
    }

    .profil-menu-onglet {
        width: 100%;
        gap: 0;
        align-items: center;
        justify-content: center;
    }

    .profil-menu-onglet h4 {
        font-size: 10px;
    }

    .profil-content-container {
        padding: 0;
    }

    .profil-infos {
        margin-bottom: 20px;
        gap: 20px;
        flex-direction: column;
    }

    .profil-pub {
        display: none;
    }

    .profil-container {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }

    .profil-row {
        font-size: 10px;
        margin-right: 15px;
    }

    .profil-image {
        display: none;
    }

    .profil-badge {
        width: 70px;
        height: 20px;
    }
}