@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.select-cyclists {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 5%;
}

.select-cyclists-antiscroll {
    width: 100%;
    height: 311px;
    background-color: white;
    display: flex;
    justify-content: center;
    z-index: 500;
    position: fixed;
    top: 50px;
}

.select-cyclists-shadow-container {
    position: fixed;
    top: 100px;
    width: 90%;
    z-index: 1000;
}
  
.select-cyclists-title {
    border-top: 10px solid #FDDD07;
    text-align: left;
    position: relative;
    box-shadow: -1px -1px 30px rgb(0, 0, 0, 0.1),
                  1px -1px 30px rgb(0, 0, 0, 0.1),
                  -1px 1px 30px rgb(0, 0, 0, 0.1),
                  1px 1px 30px rgb(0, 0, 0, 0.1);
}

.select-cyclists-shadow-mask-right, .select-cyclists-shadow-mask-bottom {
    position: absolute;
    background-color: white; 
}

.select-cyclists-shadow-mask-right {
    top: 0;
    right: 5%;
    bottom: 0;
    width: 5%; 
}

.select-cyclists-shadow-mask-bottom {
    bottom: -30px;
    left: 0;
    right: 0;
    height: 31px;
}

.select-cyclists-title h1 {
    margin: 0;
    font-size: 32px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "NerisBlack";
    font-style: italic;
}

.select-cyclists-top-container {
    position: fixed;
    top: 180px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 31px;
    z-index: 999;
    background-color: white;
}
  
.select-cyclists-rules {
    margin-top: 10px;
    text-align: left;
    width: 90%;
}

.select-cyclists-rules p {
    font-family: "Neris";
    font-size: 26px;
    padding-left: 50px;
    margin-top: 0;
}

.select-cyclists-header-pannel {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-family: "Neris";
    font-size: 20px;
}

.select-cyclists-header-pannel-content {
    display: flex;
    padding: 0;
    font-family: "NerisBlack";
    font-size: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.select-cyclists-header-pannel-content p {
    margin: 5px;
}

.select-cyclists-header-pannel p span {
    color: red;
}

.select-cyclists-header-pannel p strong {
    color: rgb(17, 196, 17);
}

.select-cyclists-next {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.select-cyclists-next button {
    background-color: #0056b3;
    border: none;
    border-radius: 80px;
    font-family: "Neris";
    font-size: 24px;
    width: 50%;    
    height: 50px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-family: "NerisBlack";
    font-style: italic;
    color: #fff70d;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.select-cyclists-next button:disabled {
    color: rgba(120, 120, 120, 0.5);
    text-shadow: none;
}

.select-cyclists-pannel-view {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 250px;
}

.select-cyclists-choice {
    display: flex;
    flex-direction: column;
    width: 70%;
}
  
.select-cyclists-controls {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding-left: 5%;
    padding-bottom: 10px;
}

.select-cyclists-sort-control {
    font-family: "NerisBlack";
    font-size: 18px;
    letter-spacing: 2px;
}
  
.select-cyclists-sort-control, .select-cyclists-search-control {
    display: flex;
    align-items: center;
}

.select-cyclists-sort-control select, .select-cyclists-search-control input {
    font-family: "Neris";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
}

.select-cyclists-sort-control label {
    padding-bottom: 6px;
}

.select-cyclists-search-control {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-cyclists-search-icon {
    position: absolute;
    left: 5px;
    top: 5px;
}
  
.select-cyclists-search-control input {
    padding-left: 30px;
}
  
.select-cyclists-table-container {
    width: 90%;
    overflow-y: auto;
    max-height: 1000px;
    margin-left: 5%;
    padding: 0;
    letter-spacing: 0;
    border: 1px solid black;
}

.select-cyclists-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    background-color: #FAFAFA;
}

.select-cyclists-table-selected-row {
    background: 0056b3;
    font-family: "NerisBlack";
}

.select-cyclists-table-container th,
.select-cyclists-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
}

.select-cyclists-table-container td {
    cursor: pointer;
    font-size: 13px;
}

.select-cyclists-table-container th {
    font-family: "NerisBlack";
    background-color: #0056b3;
    color: #fff70d;
    font-style: italic;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}

.select-cyclists-name-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15%;
}

.select-cyclists-name-info span {
    text-align: left;
    width: 60%;
}

.select-cyclists-flag-icon {
    margin-right: 10px;
}

.select-cyclists-side-info p strong {
    font-family: "NerisBlack";
}

.select-cyclists-choice-view {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.select-cyclists-scroll-container {
    display: flex;
    overflow-y: auto;
    max-height: 80%;
    border-left: 1px solid #C8CBD9;
    margin-top: 20px;
}

.select-cyclists-line-container {
    display: flex;
    flex-direction: column;
    max-height: 1000px;
    margin: 0 auto;
    flex-wrap: nowrap; 
}

.select-cyclists-selected-cell {
    flex: 1 1 auto; 
    position: relative;
}

.select-cyclists-line-container:last-child .select-cyclists-selected-cell:last-child {
    margin-right: 0;
}

::-webkit-scrollbar {
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.select-cyclists-selected-info {
    font-size: 12px;
    font-family: "NerisBlack";
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 10px;
    background-color: #EDEDED40;
    margin: 10px 10px;
    width: 150px;
    height: 80px;
    text-align: left;
    letter-spacing: 0;
    position: relative;
}

.select-cyclists-cancel-icon {
    position: absolute;
    right: 10%;
    top: 10%;
    cursor: pointer;
    z-index: 1;
}

.select-cyclists-selected-info h4 {
    margin-right: 5px;
}

.select-cyclists-selected-info h4, .select-cyclists-selected-info p {
    margin: 0;
}

.select-cyclists-selected-info p {
    font-family: "Neris";
}

.select-cyclists-selected-info strong {
    font-family: "NerisBlack";
}

@media (max-width: 1499px) and (min-width: 700px) {
    .select-cyclists-title h1 {
        font-size: 32px;
    }

    .select-cyclists-rules p {
        font-size: 24px;
    }

    .select-cyclists-next button {
        font-size: 20px;
        border-radius: 80px;
        width: 200px;
    }
}


@media (max-width: 699px) {
    .select-cyclists {
        padding-top: 0;
        padding-bottom: 5%;
    }

    .select-cyclists-shadow-container {
        width: 100%;
    }

    .select-cyclists-shadow-mask-right, .select-cyclists-shadow-mask-bottom {
        display: none;
    }

    .select-cyclists-title {
        text-align: center;
        width: 100%; 
        box-shadow: none;
        display: flex;
        justify-content: center;
    }

    .select-cyclists-title h1 {
        width: 90%;
        margin: 0;
        font-size: 28px;
        padding-left: 0px;
        padding-top: 0;
    }

    .select-cyclists-rules p {
        font-size: 22px;
        margin: 0;
        padding-left: 0px;
    }

    .select-cyclists-scroll-container {
        display: none;
    }

    .select-cyclists-pannel-view {
        flex-direction: column;
    }

    .select-cyclists-choice:first-child {
        order: 1;
    }

    .select-cyclists-choice-view:first-child {
        order: 0;
    }

    .select-cyclists-choice-view {
        width: 100%;
    }

    .select-cyclists-next {
        padding-bottom: 0;
    }

    .select-cyclists-choice {
        width: 100%;
    }

    .select-cyclists-header-pannel {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        font-size: 14px;
    }

    .select-cyclists-header-pannel-content {
        padding: 0px;
        width: 100%;
        font-size: 20px;
    }

    .select-cyclists-header-pannel-content p {
        margin-bottom: 0;
    }

    .select-cyclists-controls {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 10px;
        padding-left: 0;
        padding-bottom: 5%;
    }

    .select-cyclists-sort-control {
        font-size: 16px;
        letter-spacing: 0;
    }

    .select-cyclists-sort-control, .select-cyclists-search-control {
        width: 300px;
        align-items: flex-start;
        margin-bottom: 10px;
        margin-left: 2%;
    }

    .select-cyclists-search-control input {
        width: 235px;
    }

    .select-cyclists-table-container {
        width: 100%;
        overflow-y: auto;
        max-height: 500px;
        margin-left: 0%;
        scrollbar-width: none;
        border-right: none;
        border-left: none;
    }

    .select-cyclists-table-container::-webkit-scrollbar {
        display: none;
    }

    .select-cyclists-table-container th,
    .select-cyclists-table-container td {
        padding: 9px 3px;
    }

    .select-cyclists-table-container td {
        cursor: pointer;
        font-size: 11px;
    }
    
    .select-cyclists-table-container th {
        font-family: "NerisBlack";
        border-top: none;
        position: sticky;
        top: 0;
        z-index: 100;
        font-size: 12px;
    }

    .select-cyclists-name-info {
        justify-content: flex-start;
        padding-left: 10px;
    }

    .select-cyclists-top-container {
        margin-top: 10px;
        flex-direction: column;
    }

    .select-cyclists-next {
        margin-top: 5px;
    }

    .select-cyclists-antiscroll {
        top: 0;
        height: 400px;
    }

    .select-cyclists-pannel-view {
        margin-top: 400px;
    }
}