.my-profiles {
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 0 50px;    
    box-sizing: border-box;
}

.my-profiles-left,
.my-profiles-right {
    width: 50%;
    padding: 0px 20px 20px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.my-profiles-left.hidden,
.my-profiles-right.hidden {
    display: none;
}

.my-profiles-left.expanded,
.my-profiles-right.expanded {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.my-profiles-left.expanded h2,
.my-profiles-right.expanded h2 {
    justify-content: center;
}

.my-profiles-left h2,
.my-profiles-right h2 {
    font-size: 22px;
    font-weight: bold;
    color: #002394;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 1;
    padding-top: 10px;
}

.my-profiles-left h2::before,
.my-profiles-right h2::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    width: 110px;
    background-color: #fff70d;
    z-index: -1;
}

.my-profiles-left h2 span,
.my-profiles-right h2 span {
    color: #ffd700;
    cursor: pointer;
    text-decoration: none !important;
    text-underline-offset: 0px !important;
}

.my-profiles-right .graphic-small {
    display: block;
    height: 80%;
    object-fit: cover;
    border-radius: 8px;
}

.my-profiles-left p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
}

.my-profiles-left.expanded p {
    display: none;
}

.my-profiles-rows {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.my-profiles-left.expanded .my-profiles-row {
    height: 50px;
}

.my-profiles-row {
    display: flex;
    align-items: center;
    background-color: #f3f4f6;
    padding: 10px 15px;
    border-radius: 6px;
    height: 40px;
}

.my-profiles-row strong {
    font-size: 16px;
    font-weight: bold;
    color: #002394;
}

.my-profiles-icons {
    display: flex;
    margin-left: 20px;
    gap: 10px;
    overflow-x: auto;
    max-width: 100%;
}

.my-profiles-icons img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ddd;
}

.my-profiles-right .graphics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.my-profiles-right .graphics-grid img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-profiles-fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.my-profiles-fullscreen-image {
    max-width: 80%;
    max-height: 80%;
    background-color: white;
    border-radius: 10px;
}

.my-profiles-close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 90px;
    color: #0055ff;
    cursor: pointer;
    z-index: 1001;
}

@media (max-width: 699px) {
    .my-profiles {
        padding: 0 10px;
        flex-direction: column;
    }

    .my-profiles-left,
    .my-profiles-right {
        width: 100%;
        padding: 0;
    }

    .my-profiles-right {
        margin-bottom: 20px;
    }

    .my-profiles-left h2 span,
    .my-profiles-right h2 span {
        display: none;
    }
}
