.dashboard {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 0 50px;
}

.dashboard-top {
    display: flex;
    width: 100%;
    gap: 20px;
}

/* Top Left*/
.dashboard-top-left {
    flex: 15;
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-header strong {
    color: black;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
}

.dashboard-header strong:hover {
    text-decoration: none;
}

.dashboard-header h2 {
    font-size: 20px;
    font-weight: bold;
    color: #003399;
    position: relative;
    z-index: 1;
    margin: 0;
}

.dashboard-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-header-title span {
    font-size: 20px;
    font-weight: bold;
    color: #003399;
    position: relative;
    z-index: 1;
    padding-left: 5px;
    color: #ffd700;
    cursor: pointer;
    text-decoration: none !important;
    text-underline-offset: 0px !important;
}

.dashboard-header h2::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    background-color: #fff70d;
    z-index: -1;
}

.dashboard-header a {
    color: #666;
    font-size: 10px;
}

.dashboard-header p {
    color: #666;
    font-size: 14px;
}

.dashboard-top-left-content {
    display: flex;
    gap: 20px;
    width: 100%;
}

.dashboard-cyclist-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    flex: 4;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
}

.dashboard-cyclist-cell {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-cyclist-info {
    text-align: left;
    font-size: 11px;
}

.dashboard-cyclist-info p {
    margin-top: 0;
}

.dashboard-cyclist-name {
    display: flex;
    align-items: center;
    gap: 7px;
}

.dashboard-cyclist-flag {
    width: 16px;
    height: 16px;
}

.dashboard-cyclist-color-bar {
    height: 10px;
    width: 100%;
    border-radius: 4px;
}

.dashboard-least-profitable {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-least-profitable-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.dashboard-least-profitable-table thead {
    background-color: #0056b3;
    width: 100%;
    display: flex;
}

.dashboard-least-profitable-table th {
    background-color: #0056b3;
    color: #fff70d;
    font-weight: bold;
    padding: 10px;
    text-align: center;
}

.dashboard-least-profitable-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.dashboard-cyclist-row {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* Right Container */
.dashboard-top-right {
    flex: 7;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.dashboard-header select {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 12px;
}

.dashboard-ranking-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
}

.dashboard-ranking-table th {
    background-color: #0056b3;
    color: #fff70d;
    font-weight: bold;
    padding: 10px;
}

.dashboard-ranking-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.dashboard-ranking-table tr:nth-child(even) {
    background-color: #f3f4f6;
}

.dashboard-player-row {
    background-color: #ffd700;
    color: #000;
    font-weight: bold;
}

/* Bottom Section */
.dashboard-bottom {
    display: flex;
    width: 100%;
    gap: 20px;
}

/* Bottom Container */
.dashboard-bottom-container {
    flex: 1;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.dashboard-header h3 {
    font-size: 18px;
    font-weight: bold;
}

.dashboard-header button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #0056b3;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}

.dashboard-header button:hover {
    background-color: #00409d;
}

/* Last Races List */
.dashboard-last-races-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-last-race-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
}

.dashboard-last-race-item:last-child {
    border-bottom: none;
}

.dashboard-last-race-flag img {
    margin-right: 10px;
}

.dashboard-last-race-name {
    flex: 1;
    text-align: left;
    margin-left: 10px;
}

.dashboard-last-race-points {
    text-align: right;
    color: #666;
    font-weight: bold;
}

/* Bottom center */
.dashboard-bottom-container {
    flex: 1;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    margin-bottom: 10px;
}

.dashboard-header h3 {
    font-size: 18px;
    font-weight: bold;
}

.dashboard-next-races-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
}

.dashboard-next-races-table th {
    background-color: #0056b3;
    color: #fff70d;
    font-weight: bold;
    padding: 10px;
    border-bottom: 2px solid #ddd;
}

.dashboard-next-races-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

.dashboard-race-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    padding: 10px 18px !important;
}

.dashboard-race-flag {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0px;
}

@media (max-width: 699px) {
    .dashboard {
        padding: 0;
        overflow-y: hidden;
    }

    .dashboard-top {
        flex-direction: column;
    }

    .dashboard-top-left {
        padding: 0;
    }

    .dashboard-header {
        padding: 0 10px;
    }

    .dashboard-top-left-content {
        flex-direction: column;
    }

    .dashboard-cyclist-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .dashboard-least-profitable {
        display: flex;
        justify-content: center;
    }

    .dashboard-least-profitable-table {
        align-items: center;
        width: 70%;
    }

    .dashboard-bottom {
        flex-direction: column;
    }
}


