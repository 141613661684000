@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.faq {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.faq h1 {
    font-family: "NerisBlack";
    font-size: 32px;
    font-style: italic;
    color: #1F384C;
    margin: 0;
    padding: 50px 0;
}

.faq-container {
    width: 70vw;
    border-right: 3px solid black;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    cursor: pointer;
}

.faq-question {
    font-family: "NerisBlack";
    font-size: 22px;
    font-style: italic;
    color: #1F384C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-top: 3px solid black;
}

.faq-answer {
    font-family: "Neris";
    font-size: 16px;
    padding: 10px;   
    padding-bottom: 100px;
    padding-right: 30px;
    padding-left: 30px;
}

@media (max-width: 699px) {
    .faq {
        margin: 2%;
        width: 95vw;
        justify-content: center;
        align-items: left;
    }

    .faq h1 {
        font-size: 30px;
        margin-top: 15%;
    }

    .faq-container {
        width: 100%;
        cursor: pointer;
    }

    .faq-question {
        font-size: 20px;
        padding: 10px 20px;
    }
    
    .faq-answer {
        font-family: "Neris";
        font-size: 16px;
        padding: 10px;   
        padding-bottom: 50px;
        padding-right: 20px;
        padding-left: 20px;
    }
}