@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.navbar-desktop {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff70d;
}

.navbar-mobile {
    display: none;
}

.navbar-logo { 
    margin-left: 80px;
}

.navbar-logo-image {
    padding-top: 4px;
    height: 63px;
    width: 63px;
}

.navbar-center-links {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-link, .navbar-link-redirection {
    margin: 0 20px;
    text-decoration: none;    
    font-size: 16px;
    font-family: "NerisBlack";
    font-style: italic;
}

.navbar-link {
    color: #002491;
}

.navbar-link-redirection {
    color: #fff70d;
    -webkit-text-stroke: 0.5px #002394;
    text-shadow:
        -1px -1px 0 #002394,
         1px -1px 0 #002394,
        -1px  1px 0 #002394,
         1px  1px 0 #002394;
}

.navbar-link.selected {
    color: #3b5dcc;
}

.navbar-item {
    position: relative;
    display: flex;
    align-items: center; 
}

/* Mobile ---------------------------- */
@media (max-width: 699px) {
    body.menu-open {
        position: fixed;
        width: 100%;
        top: calc(-1 * var(--scroll-y));
    }
    
    .navbar-desktop {
        display: none;
    }

    .navbar-mobile {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000000;
        background-color: transparent;
        transition: opacity 0.5s ease;
        font-family: "NerisBlack";
        font-size: 18px;
    }

    .navbar-menu-icon {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 2;
        display: inline-flex;
        background-color: none;
        cursor: pointer;
        width: 100px;
        height: 100px;
    }

    .navbar-hamburger-icon {
        display: inline-flex;
        width: 65%;
        height: 65%;  
        margin-right: 5px;
        fill: #FDDD07;
    }

    .navbar-hamburger-icon.active {
        display: inline-flex;
        width: 65%;
        height: 65%;  
        margin-right: 5px;
        fill: black;
    }
    
    .navbar-sidebar {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: 0;
        z-index: 1;
        left: -100vw;
        width: 100vw;
        height: 100%;
        padding-top: 60px;
        color: black;
        background-color: #FDDD07;
        transition: left 0.3s ease;
    }

    .navbar-sidebar.active {
        overflow: hidden;
        transform: translateX(80%);
        transition: transform 0.3s ease;
    }

    .navbar-sidebar-link {
        display: inline-block;
        padding: 10px;
        padding-left: 30%;
        padding-bottom: 20px;
        text-decoration: none;
        color: inherit;
    }

    .navbar-sidebar-link:first-child {
        margin-top: 40px;
    }
}