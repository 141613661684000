@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.reset-password {
    text-align: center;
    position: relative;
    font-family: "NerisBlack";
}

.reset-password-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: "Neris";
}

.reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 50%;
}

.reset-password-form-title {
    text-align: center;
    color: black;
    font-family: "Neris";
    font-size: 22px;
}

.reset-password-form-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px 20px;
    color: black;
    width: 35%;
}

.reset-password-form-input input {
    border: none;
    background-color: #F6F8FB;
    border-radius: 16px;
    padding: 15px 30px;
    height: 30px;
    width: 85%;
}

.reset-password-form-input label {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: black;
    padding-left: 10px;
    padding-bottom: 10px;
}

.reset-password-form-input input[type="password"] {
    -webkit-text-security: disc;
}

.reset-password-popup {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;    
}

.reset-password-popup-content {
    background-color: white;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
    width: 350px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.reset-password-popup-content p {
    text-align: center;
    font-family: "Neris";
    font-size: 24px;
}

.reset-password-form-row.button-row button {
    width: 100%;
    padding: 15px 20px;
    margin-top: 40px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "NerisBlack";
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

.reset-password-popup-content button {
    font-family: "NerisBlack";
    font-style: italic;
    color: white;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 250px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: #FDDD07;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1499px) and (min-width: 700px) {
    .reset-password-popup-content button {
        font-size: 20px;
        border-radius: 80px;
        width: 200px;
    }
}

@media (max-width: 699px) {
    .reset-password-form {
        width: 100%;
        justify-content: center;
    }

    .reset-password-form-title {
        margin-top: 50px;
        width: 100%;
    }

    .reset-password-form p {
        width: 80%;
    }

    .reset-password-form-input {
        width: 70%;
    }

    .reset-password-form-row.button-row {
        width: 70%;
    }
    
    .reset-password-form-row.button-row button {
        margin-bottom: 20px;
    }

    .reset-password-popup-content button {
        font-size: 20px;
        border-radius: 80px;
        width: 200px;
        height: 50px;
    }
}