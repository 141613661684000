@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.scale {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.scale h1 {
    font-family: "NerisBlack";
    font-size: 32px;
    font-style: italic;
    color: #1F384C;
    margin: 0;
    padding: 3% 0;
}

.scale-image {
    width: 100%;
}

@media (max-width: 699px) {
    .scale {
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .scale h1 {
        font-size: 22px;
        padding: 0 0;
        margin: 0;
        margin-top: 70px;
        margin-bottom: 20px;
        text-align: center;
    }   
}
