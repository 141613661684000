@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.change-bonus-cyclist{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 71px;
    padding-top: 50px;
}
  
.change-bonus-cyclist-title {   
    border-top: 10px solid #FDDD07;
    text-align: left;
    width: 90%; 
    position: relative;
    z-index: 1;
    box-shadow: -1px -1px 30px rgb(0, 0, 0, 0.1),
                  1px -1px 30px rgb(0, 0, 0, 0.1),
                  -1px 1px 30px rgb(0, 0, 0, 0.1),
                  1px 1px 30px rgb(0, 0, 0, 0.1);
}

.change-bonus-cyclist-shadow-container {
    position: relative;
    width: 90%;
}

.change-bonus-cyclist-shadow-mask-right, .change-bonus-cyclist-shadow-mask-bottom {
    position: absolute;
    background-color: white; 
    z-index: 2; 
}

.change-bonus-cyclist-shadow-mask-bottom {
    bottom: -30px;
    left: 0;
    right: 0;
    height: 31px;
}

.change-bonus-cyclist-title h1 {
    margin: 0;
    font-family: "NerisBlack";
    font-style: italic;
    font-size: 32px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.change-bonus-cyclist-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    height: 120px;
}
  
.change-bonus-cyclist-rules {
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 8%;
}

.change-bonus-cyclist-rules p {
    font-family: "NerisBlack";
    font-size: 30px;
}

.change-bonus-cyclist-actions {
    text-align: center;
    display: flex;
    gap: 7%;
    align-items: center;
    height: 100%;
    padding-right: 3%;
}

.change-bonus-cyclist-change-step {
    text-align: center;
    display: flex;
    gap: 7%;
    align-items: center;
    height: 100%;
    padding-right: 3%;
}

.change-bonus-cyclist-change-step button {
    font-family: "NerisBlack";
    font-style: italic;
    color: #fff70d;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 250px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.change-bonus-cyclist-change-step button:disabled {
    color: rgba(120, 120, 120, 0.5);
    text-shadow: none;
}

.change-bonus-cyclist-choice {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
}
  
.change-bonus-cyclist-table-container {
    width: 80%;
    overflow-y: auto;
    max-height: 500px;
    margin-left: 5%;
    padding: 0;
    letter-spacing: 0px;
    border: 1px solid black;
    border-right: none;
    border-bottom: none;
}

.change-bonus-cyclist-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 11px;
    background-color: #FAFAFA;
}

.change-bonus-cyclist-table-selected-row {
    background: yellow;
    font-family: "NerisBlack";
}

.change-bonus-cyclist-table-container th,
.change-bonus-cyclist-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
}

.change-bonus-cyclist-table-container td {
    cursor: pointer;
    font-size: 13px;
}

.change-bonus-cyclist-table-container th {
    font-family: "NerisBlack";
    font-size: 14px;
    background-color: #FFE220;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
}

.change-bonus-cyclist-name-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15%;
}

.change-bonus-cyclist-name-info span {
    text-align: left;
    width: 60%;
}

.change-bonus-cyclist-line-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 250px;
    overflow-y: auto;
    width: 20%;
    margin: 0 auto;
    flex-wrap: nowrap; 
}

.change-bonus-cyclist-selected-cell {
    flex: 1 1 auto; 
    position: relative;
}

.change-bonus-cyclist-line-container:last-child .change-bonus-cyclist-selected-cell:last-child {
    margin-right: 0;
}

::-webkit-scrollbar {
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.change-bonus-cyclist-selected-info {
    font-size: 12px;
    font-family: "NerisBlack";
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 10px;
    background-color: #EDEDED40;
    margin: 10px 10px;
    width: 150px;
    height: 80px;
    text-align: left;
    letter-spacing: 0;
    position: relative;
}

.change-bonus-cyclist-cancel-icon {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    z-index: 1;
}

.change-bonus-cyclist-selected-info h4 {
    margin-right: 5px;
}

.change-bonus-cyclist-selected-info h4, .change-bonus-cyclist-selected-info p {
    margin: 0;
}

.change-bonus-cyclist-selected-info p {
    font-family: "Neris";
}

.change-bonus-cyclist-selected-info strong {
    font-family: "NerisBlack";
}

.change-bonus-cyclist-flag-icon {
    margin-right: 10px;
}

.change-bonus-cyclist-popup {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;    
}

.change-bonus-cyclist-popup-content {
    background-color: white;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
    width: 350px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.change-bonus-cyclist-popup-content p {
    text-align: center;
    font-family: "Neris";
    font-size: 24px;
}