@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}


.stats {
    margin: 50px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    position: relative;
}

.stats h1 {
    font-family: "NerisBlack";
    font-style: italic;
    font-size: 32px;
    color: #002394;
}

.stats p {
    font-family: "Neris";
    font-size: 20px;
    margin-top: 0;
}

.stats-graphics-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
}

.stats-graphics-grid img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 699px) {
    .stats {
        margin: 0;
        padding: 0 10px;
    }

    .stats h1 {
        font-size: 22px;
        margin-top: 70px;
    }

    .stats-graphics-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}