@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.create-team{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 71px;
    padding-top: 50px;
}

.create-team-shadow-container {
    position: relative;
    width: 90%;
}
  
.create-team-title {   
    border-top: 10px solid #FDDD07;
    text-align: left;
    width: 90%; 
    position: relative;
    z-index: 1;
    box-shadow: -1px -1px 30px rgb(0, 0, 0, 0.1),
                  1px -1px 30px rgb(0, 0, 0, 0.1),
                  -1px 1px 30px rgb(0, 0, 0, 0.1),
                  1px 1px 30px rgb(0, 0, 0, 0.1);
}

.create-team-shadow-mask-right, .create-team-shadow-mask-bottom {
    position: absolute;
    background-color: white; 
    z-index: 2; 
}

.create-team-shadow-mask-right {
    top: 0;
    right: 5%;
    bottom: 0;
    width: 5%; 
}

.create-team-shadow-mask-bottom {
    bottom: -30px;
    left: 0;
    right: 0;
    height: 30px;
}

.create-team-title h1 {
    margin: 0;
    font-size: 32px;
    padding-left: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: italic;
    font-family: "NerisBlack";
}


.create-team-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.create-team-left-container, .create-team-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.create-team-button {
    font-family: "NerisBlack";
    font-style: italic;
    cursor: pointer;
    font-size: 24px;
    border-radius: 80px;
    width: 250px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    color: #fff70d;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

@media (max-width: 699px) {
    .create-team-title {
        width: 100%;
    }

    .create-team-title h1 {
        padding: 0;
        font-size: 24px;
    }

    .create-team-shadow-mask-right {
        display: none;
    }

    .create-team-container {
        flex-direction: column;
    }

    .create-team-left-container img,
    .create-team-right-container img {
        max-width: 100%;
        max-height: 60vw;
    }
}


