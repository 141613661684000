@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

/* Section 1 */
.home-section1-background {
    position: relative;
}

.home-section1-background-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-top: 71px;
}

/* Section 2 */

.home-section2 {
    display: flex;
    margin-top: 30px;
}

.home-section2-left-container {
    width: 70%;
    margin-bottom: 50px;
}

.home-section2-right-container {
    width: 30%;
    position: relative;
}

.home-section2-text-content {
    width: 100%;
    font-family: "Neris";
    display: flex;
    flex-direction: column;
    justify-content: center;    
    padding-left: 150px;
}

.home-section2-text-content h1, .home-section2-text-content h2 {
    font-style: italic;
    display: flex;
    align-items: center;    
    font-family: "NerisBlack";
    margin: 0;
}

.home-section2-text-content h1 {
    font-size: 36px;
    color: #002394;
    font-style: italic;
}

.home-section2-text-content h2 {
    font-size: 28px;
    margin-top: 20px;
}

.home-section2-text-content p {
    font-size: 18px;  
    margin: 0; 
    margin-top: 20px;
}

.home-section2-right-image {
    position: absolute;
    bottom: 0;
    right: 50px;
    height: 350px;
}

.home-section2-button {
    font-style: italic;
    background-color: white;
    color: #002394;
    font-family: "NerisBlack";
    font-size: 28px;
    letter-spacing: 2px;
    border: 5px solid #002394;
    border-radius: 70px;
    padding: 10px 20px;
    margin-top: 20px;
    margin-left: 40px;
    cursor: pointer;    
}

/* Section 3 */
.home-section3 {
    display: flex;
    flex-direction: column;
}

/* Section 1: Create Team */
.home-section3-container-create-team {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 1.5rem;
    padding-right: 200px;
}

.home-section3-left-create-team,
.home-section3-right-create-team {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section3-left-create-team {
    flex: 1;
}

.home-section3-right-create-team {
    flex: 2;
}

.home-section3-left-image-create-team {
    max-width: 200px;
    height: auto;
    object-fit: contain;
    opacity: 40%;
}

.home-section3-right-image-create-team {
    max-width: 350px;
    height: auto;
    object-fit: contain;
    margin-right: -100px;
}

.home-section3-content-create-team {
    flex: 3;
    text-align: left;
    padding: 0 1rem;
    margin-left: -2rem;
}

.home-section3-title-create-team {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    color: #0033cc;
    margin-bottom: 10px;
}

.home-section3-content-create-team p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}


/* Section 2: Choose Calendar */
.home-section3-container-choose-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    background-color: #ebeeff;
    gap: 0;
    height: 250px;
}

.home-section3-left-choose-calendar,
.home-section3-content-choose-calendar {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.home-section3-left-choose-calendar {
    justify-content: center;
}

.home-section3-left-image-choose-calendar {
    max-width: 60%;
    height: auto;
    object-fit: contain;
}

.home-section3-content-choose-calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-right: 50px;
}

.home-section3-title-choose-calendar {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    color: #0033cc;
    margin-bottom: 10px;
}

.home-section3-content-choose-calendar p {
    font-size: 14px;
    margin-bottom: 10px;
}

/* Section 3: Collect Badges */
.home-section3-container-collect-badges {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 0;
    height: 250px;
    position: relative;
    padding: 0 200px;
}

.home-section3-content-collect-badges {
    flex: 0 0 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    z-index: 1;
    height: 100%;
}

.home-section3-title-collect-badges {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    color: #0033cc;
    margin-bottom: 10px;
}

.home-section3-badge {
    position: absolute;
    top: 0;
    left: 80%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    font-weight: bold;
    color: #fced7c;
    font-style: italic;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.home-section3-left-image-collect-badges {
    position: absolute;
    bottom: 0;
    left: -180px;
    max-width: 350px;
    height: auto;
    object-fit: contain;
    opacity: 0.4;
}

.home-section3-right-collect-badges {
    flex: 0 0 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.home-section3-right-image-collect-badges {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 699px) {
    .home-section1-background-image {
        padding-top: 0;
    }
    
    .home-section2-left-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 10px;
    }
    
    .home-section2-right-container {
        display: none;
    }

    .home-section2-text-content {  
        padding-left: 0;
    }

    .home-section3-container-create-team {
        flex-direction: column;
        padding-right: 0px;
        gap: 0;
    }

    .home-section3-content-create-team {
        margin-left: 0;
    }

    .home-section3-right-image-create-team {
        margin-right: 0;
    }

    .home-section3-container-choose-calendar {
        flex-direction: column;
    }

    .home-section3-left-choose-calendar {
        display: none;
    }

    .home-section3-content-choose-calendar {
        padding: 0 10px;
    }

    .home-section3-badge {
        transform: translate(-50%, -50%);
        padding: 0;
        left: 50%;
    }

    .home-section3-container-collect-badges {
        padding: 0;
        flex-direction: column;
    }

    .home-section3-content-collect-badges {
        flex: 1;
        padding: 0 10px;
    }

    .home-section3-title-collect-badges {
        margin-top: 30px;
    }

    .home-section3-left-image-collect-badges {
        left: -50px;
    }

    .home-section3-right-collect-badges {
        margin-top: 30px;
    }
}


