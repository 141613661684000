@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.my-team {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.my-team-sort-control {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0;
    height: 100%;
    gap: 30px;
}

.my-team-sort-control h2 {
    font-family: "NerisBlack";
    font-size: 22px;
    font-weight: bold;
    color: #003399;
    position: relative;
    z-index: 1;
}

.my-team-sort-control h2::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    background-color: #fff70d;
    z-index: -1;
}

.my-team-sort-control select {
    font-family: "Neris";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
    margin-left: 10px;
}


.my-team-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
}
  
.my-team-table-container {
    width: 75%;
    overflow-y: auto;
    max-height: 500px;
    padding: 0;
    letter-spacing: 0px;
}

.my-team-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 10px;
    background-color: #FAFAFA;
}

.my-team-table-container th,
.my-team-table-container td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.my-team-table-container td {
    font-family: "Neris";
    cursor: pointer;
    font-size: 12px;
}

.my-team-table-container th {
    font-family: "NerisBlack";
    background-color: #0056b3;
    color: #fff70d;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}

.my-team-name-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.my-team-name-info span {
    text-align: left;
    width: 30%;
}

.my-team-flag-icon {
    margin-right: 10px;
}

@media (max-width: 699px) {
    .my-team {
        margin: 0;
    }

    .my-team h1 {
        font-size: 26px;
        padding: 0 0;
        margin: 0;
        margin-top: 15%;
        margin-bottom: 5%;
        text-align: center;
    } 
    
    .my-team-sort-control {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }

    .my-team-table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .my-team-table-container {
        scrollbar-width: none;
        width: 100%;
        max-height: 65vh;
    }

    .my-team-table-container::-webkit-scrollbar {
        display: none;
    }

    .my-team-name-container {
        padding-left: 0%;
    }

    .my-team-table-container th,
    .my-team-table-container td {
        padding: 9px 3px;
    }

    .my-team-table-container td {
        font-size: 12px;
    }

    .my-team-table-container th {
        font-size: 14px;
    }

    .my-team-name-info {
        justify-content: flex-start;
        padding-left: 5px;
    }

    .my-races-name-container-image {
        left: -10px !important;
    }
}