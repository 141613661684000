@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.quoting {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.quoting h1 {
    font-family: "NerisBlack";
    font-size: 32px;
    font-style: italic;
    color: #1F384C;
    margin: 0;
    padding: 3% 0;
}

.quoting-choice {
    text-align: center;
    display: flex;
    gap: 10%;
    align-items: center;
    height: 100%;
    padding-bottom: 3%;
}

.quoting-choice button {
    font-family: "NerisBlack";
    font-style: italic;
    cursor: pointer;
    font-size: 20px;
    border-radius: 80px;
    width: 200px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: #0056b3;
    color: #fff70d;
    border: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.quoting-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
}
  
.quoting-table-container {
    width: 80%;
    overflow-y: auto;
    max-height: 800px;
    padding: 0;
    letter-spacing: 0px;
}

.quoting-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 10px;
    background-color: #FAFAFA;
}

.quoting-table-container th,
.quoting-table-container td {
    border: 1px solid black;
    padding: 7px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.quoting-table-container td {
    font-family: "Neris";
    cursor: pointer;
    font-size: 12px;
}

.quoting-table-container th {
    font-family: "NerisBlack";
    background-color: #0056b3;
    color: #fff70d;
    font-style: italic;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}

.quoting-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15%;
}

.quoting-name-container span {
    text-align: left;
    width: 60%;
}

.quoting-flag-icon {
    margin-right: 10px;
}

@media (max-width: 699px) {
    .quoting {
        margin: 0;
    }

    .quoting h1 {
        font-size: 22px;
        padding: 0 0;
        margin: 0;
        margin-top: 80px;
        margin-bottom: 5%;
        text-align: center;
    }   

    .quoting-table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .quoting-table-container {
        scrollbar-width: none;
        width: 100%;
        max-height: 65vh;
    }

    .quoting-table-container::-webkit-scrollbar {
        display: none;
    }

    .quoting-choice {
        display: flex;
        flex-direction: column;
        gap: 0%;
        align-items: center;
        height: 100%;
        padding-bottom: 5%;
    }

    .quoting-choice button {
        font-size: 20px;
        width: 200px;
        height: 50px;
        margin: 5px 0;
    }
}