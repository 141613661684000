@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.rules {
    margin: 50px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    width: 95vw;
    position: relative;
}

.rules h1, .rules h2 {
    font-family: "NerisBlack";
    font-style: italic;
    font-size: 32px;
    color: #002394;
}

.rules-container {
    overflow-y: auto;
    height: 70vh;
}

.rules-section h3 {
    font-size: 18px;
    font-family: "NerisBlack";
    margin-bottom: 0;
}

.rules-section p {
    font-size: 18px;
    font-family: "Neris";
    margin-top: 0;
}

.rules-section p strong {
    font-size: 18px;
    font-family: "NerisBlack";
}

.rules-emblemes-container2 {
    background-color: #ebeeff;
}

.rules-emblemes-container h3, .rules-emblemes-container2 h3 {
    font-family: "NerisBlack";
    font-size: 32px;
    color: black;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 0;
}

.rules-emblemes {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.rules-emblemes img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.rules-fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rules-fullscreen-image {
    max-width: 80%;
    max-height: 80%;
    background-color: white;
    border-radius: 10px;
}

.rules-close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 90px;
    color: #0055ff;
    cursor: pointer;
    z-index: 1001;
}

@media (max-width: 699px) {
    .rules {
        margin: 0 2%;
        width: 95vw;
        justify-content: center;
        align-items: left;
    }

    .rules h1 {
        font-size: 30px;
        margin-top: 15%;
    }

    .rules-section h3 {
        font-size: 22px;
    }
    
    .rules-section p {
        font-size: 16px;
    }
    
    .rules-section p strong {
        font-size: 22px;
    }

    .rules-container {
        height: 80vh;
    }
}