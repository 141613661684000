@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.set-race-ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 5%;
}

.set-race-ranking-shadow-container {
    position: relative;
    width: 90%;
}
  
.set-race-ranking-title {
    border-top: 10px solid #FDDD07;
    text-align: left;
    position: relative;
    z-index: 1;
    box-shadow: -1px -1px 30px rgb(0, 0, 0, 0.1),
                  1px -1px 30px rgb(0, 0, 0, 0.1),
                  -1px 1px 30px rgb(0, 0, 0, 0.1),
                  1px 1px 30px rgb(0, 0, 0, 0.1);
}

.set-race-ranking-shadow-mask-right, .set-race-ranking-shadow-mask-bottom {
    position: absolute;
    background-color: white; 
    z-index: 2; 
}

.set-race-ranking-shadow-mask-right {
    top: 0;
    right: 5%;
    bottom: 0;
    width: 5%; 
}

.set-race-ranking-shadow-mask-bottom {
    bottom: -30px;
    left: 0;
    right: 0;
    height: 31px;
}

.set-race-ranking-title h1 {
    margin: 0;
    font-size: 40px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "NerisBlack";
    font-style: italic;
}

.set-race-ranking-button-container {
    margin-top: 5%;
    margin-bottom: 3%;
}

.set-race-ranking-button-container button {
    background-color: #fff70d;
    border: none;
    border-radius: 80px;
    font-family: "Neris";
    font-size: 24px;   
    cursor: pointer;
    margin: 0;
    padding: 20px 30px;
    font-family: "NerisBlack";
    font-style: italic;
    color: white;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.set-race-ranking-button-container button {
    background-color: #FDDD07;
    border: none;
    border-radius: 80px;
    font-family: "Neris";
    font-size: 24px;   
    cursor: pointer;
    margin: 0;
    padding: 20px 30px;
    font-family: "NerisBlack";
    font-style: italic;
    color: white;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.button-disabled {
    background-color: #808080!important;
}

.set-race-ranking-choice-step {
    display: flex;
}

.set-race-ranking-choice-step p, input {
    margin: 10px;
    padding: 5px;
}

.set-race-ranking-select-race {
    width: 500px;
    z-index: 100000;
}

.set-race-ranking-choose-race {
    font-weight: 600;
    font-size: 18px;
}

.set-race-ranking-ranking-view {
    width: 100%;
    display: flex;
    justify-content: center;
}

.set-race-ranking-select-cyclist {
    width: 30%;
    margin-left: 30px;
}

.set-race-ranking-cyclists-view {
    width: 70%;
}

.set-race-ranking-controls {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    padding-left: 5%;
    padding-bottom: 20px;
}

.set-race-ranking-sort-control {
    font-family: "NerisBlack";
    font-size: 18px;
    letter-spacing: 2px;
}
  
.set-race-ranking-sort-control, .set-race-ranking-search-control {
    display: flex;
    align-items: center;
}

.set-race-ranking-sort-control select, .set-race-ranking-search-control input {
    font-family: "Neris";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
}

.set-race-ranking-sort-control label {
    padding-bottom: 6px;
}

.set-race-ranking-search-control {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.set-race-ranking-search-icon {
    position: absolute;
    left: 5px;
    top: 5px;
}
  
.set-race-ranking-search-control input {
    padding-left: 30px;
}

.set-race-ranking-table-container {
    width: 90%;
    overflow-y: auto;
    max-height: 1000px;
    margin-left: 5%;
    padding: 0;
    letter-spacing: 0;
    border: 1px solid black;
}

.set-race-ranking-table-container table, .set-race-ranking-select-cyclist table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    background-color: #FAFAFA;
}

.set-race-ranking-table-container th,
.set-race-ranking-table-container td,
.set-race-ranking-select-cyclist th,
.set-race-ranking-select-cyclist td {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
}

.set-race-ranking-table-container td,
.set-race-ranking-select-cyclist td {
    cursor: pointer;
    font-size: 13px;
}

.set-race-ranking-table-container th,
.set-race-ranking-select-cyclist th {
    font-family: "NerisBlack";
    background-color: #FFE220;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}
