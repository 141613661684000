@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

.calendar {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.calendar h1 {
    font-family: "NerisBlack";
    font-size: 32px;
    font-style: italic;
    color: #1F384C;
    margin: 0;
    padding: 3% 0;
}

.calendar-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
}
  
.calendar-table-container {
    width: 80%;
    overflow-y: auto;
    max-height: 800px;
    padding: 0;
    letter-spacing: 0px;
}

.calendar-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Neris";
    font-size: 10px;
    background-color: #FAFAFA;
}

.calendar-table-container th,
.calendar-table-container td {
    border: 1px solid black;
    padding: 7px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.calendar-table-container td {
    font-family: "Neris";
    cursor: pointer;
    font-size: 12px;
}

.calendar-table-container th {
    font-family: "NerisBlack";
    background-color: #0056b3;
    color: #fff70d;
    font-style: italic;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}

.calendar-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15%;
}

.calendar-name-container span {
    text-align: left;
    width: 60%;
}

.calendar-flag-icon {
    margin-right: 10px;
}

@media (max-width: 699px) {
    .calendar {
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .calendar h1 {
        font-size: 22px;
        padding: 0 0;
        margin-top: 70px;
        margin-bottom: 20px;
        text-align: center;
    }   

    .calendar-table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .calendar-table-container {
        scrollbar-width: none;
        width: 100%;
        max-height: 70vh;
    }

    .calendar-table-container::-webkit-scrollbar {
        display: none;
    }
}