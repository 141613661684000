@font-face {
    font-family: "Neris";
    src: url("../../fonts/Neris-Light.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NerisBlack";
    src: url("../../fonts/Neris-Black.otf") format("truetype");
    font-display: swap;
}

.login {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    text-align: center;
}

.login-left-banner {
    position: absolute;
    left: 0;
    bottom: -35px;
}

.login-right-banner {
    position: absolute;
    right: 0;
    bottom: 0;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding: 2% 0;
}

.login-form-control {
    position: relative;
    margin-bottom: 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-family: "Neris";
    font-size: 14px;
    color: #1B1446;
}

.login-form-control input {
    border: none;
    background-color: #F6F8FB;
    border-radius: 16px;
    padding: 10px;
    width: 100%;
    height: 30px;
    padding-left: 40px;
    margin-left: 10px;
}

.login-form-control input[type="password"] {
    -webkit-text-security: disc;
}

.login-icon {
    position: absolute;
    left: 0px;
    top: 50%;
    color: yellow;
}

.login-form-visibility-icon {
    position: absolute;
    right: 0px;
    top: 55%;
    color: yellow;
    cursor: pointer;
}

.login-form-control label {
    margin-bottom: 10px;
    align-self: flex-start;
    text-align: left;
}

.login-form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Neris";
    font-size: 14px;
    color: #1B1446;
}

.login-remember-me {
    display: flex;
    align-items: center;
}

.login-remember-me input {
    margin-right: 5px;
}

.login-forgot-password {
    color: #000;
    text-decoration: none;
    font-size: 0.9em;
}

.login-button {
    background: #000;
    color: #fff;
    border: none;
    padding: 15px;
    cursor: pointer;
    transition: background 0.3s;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    font-family: "NerisBlack";
    font-size: 16px;
    width: 100%;
}

.login-button:hover {
    background: #333;
}

@media (max-width: 699px) {
    .login-right-banner {
       display: none;
    }

    .login-left-banner {
        z-index: -5;
    }
}